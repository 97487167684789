// Bootstrap theme overrides
// $primary: #f96618;
$primary: #E6AD19;
// $secondary: #113657;
$secondary: #2E3A47;
$body-bg: #f0f2f5;
$jumbotron-bg: white;

// Bootstrap style import
@import '~bootstrap/scss/bootstrap';
// React Bootstrap Typeahead style import
@import '~react-bootstrap-typeahead/css/Typeahead.css';

// font used on immobiliallasta.it
@import "../styles/fonts/visby/style.css";


// Bootstrap classes overrides
.btn-primary {
  &:hover {
    border-color: $secondary;
    background-color: $secondary;
  }
}

.text-white {
    color: white;
}

// immobiliallasta.it style
body {
    font-family: 'Open Sans', sans-serif;
    font-family: 'Roboto', sans-serif;
    font-family: 'Nunito', sans-serif;
    background: #fafafa;
}

h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #37404d;
}

h1, h2, .h1, .h2 {
    font-family: 'Visby Extrabold', sans-serif;
}

h3, h4, .h3, .h4 {
    font-family: 'Visby Semibold', sans-serif;
}

h5, h6, .h5, .h6 {
    font-family: 'Visby Medium', sans-serif;
}

p, label {
    font-size: 16px;
    line-height: 26px;
    color: #535353;
}


