/* #### Generated By: http://www.cufonfonts.com #### */

    @font-face {
    font-family: 'Visby Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Regular'), url('VisbyRegular.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Bold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Bold Italic'), url('VisbyBold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Bold'), url('VisbyBold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Extrabold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Extrabold Italic'), url('VisbyExtrabold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Extrabold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Extrabold'), url('VisbyExtrabold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Heavy Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Heavy Italic'), url('VisbyHeavy Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Heavy'), url('VisbyHeavy.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Italic'), url('VisbyItalic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Light Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Light Italic'), url('VisbyLight Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Light';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Light'), url('VisbyLight.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Medium Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Medium Italic'), url('VisbyMedium Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Medium';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Medium'), url('VisbyMedium.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Semibold Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Semibold Italic'), url('VisbySemibold Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Semibold'), url('VisbySemibold.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Thin Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Thin Italic'), url('VisbyThin Italic.woff') format('woff');
    }
    

    @font-face {
    font-family: 'Visby Thin';
    font-style: normal;
    font-weight: normal;
    src: local('Visby Thin'), url('VisbyThin.woff') format('woff');
    }